import { Component, Input, OnInit } from '@angular/core';
import { AppConstant } from 'src/app/core/constants/enums';
declare var $: any;

@Component({
  selector: 'app-alert-message',
  standalone:false,
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss'],
})
export class AlertMessageComponent implements OnInit {
  @Input() type!: string;
  @Input() message!: any | [];
  @Input() hideTime = 5000;
  typeCheck: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.typeCheck = Array.isArray(this.message);
    if (this.type === AppConstant.SUCCESS) {
      setTimeout(() => $('.alert').hide(), this.hideTime);
    }
  }
}
