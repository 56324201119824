import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class BypassSecurityService {

  constructor(private _sanitizer : DomSanitizer) { }

  customBypassSecurityTrustResourceUrl(value : string): SafeResourceUrl {
    const host = '';
    let url = this._sanitizer.sanitize(SecurityContext.URL,host+ value);
    return this._sanitizer.bypassSecurityTrustResourceUrl(url || '');
  }


}
