import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { DataPassingService } from "src/app/support-service/data-passing.service";
import { StorageService } from "src/app/core/store/storage.service";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  isSessionActive$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private sessionMessage: DataPassingService,
    private storage: StorageService
  ) {}

  checkUserSession(error: any) {
    if (error.status == 401) {
      this.storage?.setToken("");
      let userNameShow: any = this.storage?.getUserNameShow;
      this.storage.setUserNameShow(userNameShow);
      let userRole: any = this.storage?.getUserRole;
      this.storage?.setUserRole(userRole);
      let user = this.storage?.getUsersData;
      let url: any = this.router.url;
      this.storage?.setRouteUrl(url);
      this.storage?.setUsername(user?.username);
      this.sessionMessage.changeSessionMessage(
        "To protect your privacy, we have logged you out."
      );
      this.storage?.clearStorage();
      this.isSessionActive$.next(false);
      this.router.navigate(["/"]);
    }
  }

  get isLoggedIn() {
    return this.isSessionActive$.asObservable();
  }
}
