<div class="alert alert-{{ type }} text-start" role="alert">
  <ng-content></ng-content>
  <ng-container *ngIf="typeCheck; else textMsg">
    <ul class="pl-inherit">
      <li *ngFor="let alert of message">{{ alert }}</li>
    </ul>
  </ng-container>
  <ng-template #textMsg>
    <ul class="pl-inherit" *ngIf="message">
      <li>{{ message }}</li>
    </ul>
  </ng-template>
</div>
