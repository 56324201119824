import { Injectable } from "@angular/core";
import { LocalStorageService } from "ngx-webstorage";
import { AppConstant } from "../constants/enums";
import { AnyCatcher } from "rxjs/internal/AnyCatcher";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  constructor(private storage: LocalStorageService) {}

  get getUsersData() {
    return this.storage?.retrieve("usersData");
  }
  setUsersData(data: any) {
    this.storage?.store("usersData", data);
  }
  clearUsersData() {
    this.storage?.clear("usersData");
  }
  setProviderDetails(data: any) {
    this.storage?.store("providerDetails", data);
  }
  get getProviderDetails() {
    return this.storage?.retrieve("providerDetails");
  }
  clearProviderDetails() {
    this.storage?.clear("providerDetails");
  }
  get getProfile() {
    return this.storage?.retrieve("profile");
  }
  setProfile(data: any) {
    this.storage?.store("profile", data);
  }
  get getUserType() {
    return this.storage?.retrieve("user_type");
  }
  setUserType(data: any) {
    this.storage?.store("user_type", data);
  }

  clearIsSuperAdmin() {
    this.storage?.clear("isSuperAdmin");
  }
  clearStorage() {
    this.storage?.clear();
  }
  get getTaskableId() {
    return this.storage?.retrieve("taskable_id");
  }
  get getSpecializations() {
    return this.storage?.retrieve("specializations");
  }
  setSpecializations(data: any) {
    this.storage?.store("specializations", data);
  }

  removeLocalStorageItem(key: string) {
    this.storage?.clear(key);
  }
  /* Here updated getter and setter */
  setDebugMode(value: any) {
    this.storage?.store(AppConstant.DEBUG_MODE, value);
  }

  get getDebugMode() {
    return this.storage?.retrieve(AppConstant.DEBUG_MODE);
  }

  setIsSuperAdmin(value: any) {
    this.storage?.store(AppConstant.IS_SUPER_ADMIN, value);
  }

  get getIsSuperAdmin() {
    return this.storage?.retrieve(AppConstant.IS_SUPER_ADMIN);
  }

  setToken(value: any) {
    this.storage?.store(AppConstant.TOKEN, value);
  }

  get getToken() {
    return this.storage?.retrieve(AppConstant.TOKEN);
  }

  setUserRole(value: any) {
    this.storage?.store(AppConstant.USER_ROLE, value);
  }

  get getUserRole() {
    return this.storage?.retrieve(AppConstant.USER_ROLE);
  }

  setRToken(value: any) {
    this.storage?.store(AppConstant.RTOKEN, value);
  }

  get getRToken() {
    return this.storage?.retrieve(AppConstant.RTOKEN);
  }

  setProfilePic(value: any) {
    this.storage?.store("profile_pic", value);
  }

  get getProfilePic() {
    return this.storage?.retrieve("profile_pic");
  }

  setUserNameShow(value: any) {
    this.storage?.store("userNameShow", value);
  }

  get getUserNameShow() {
    return this.storage?.retrieve("userNameShow");
  }

  setImpersonateUser(value: any) {
    this.storage?.store("impersonateUser", value);
  }

  get getImpersonateUser() {
    return this.storage?.retrieve("impersonateUser");
  }

  setTaskSupported(value: any) {
    this.storage?.store("taskSupported", value);
  }

  get getTaskSupported() {
    return this.storage?.retrieve("taskSupported");
  }

  setTaskTitle(value: any) {
    this.storage?.store("taskTitle", value);
  }

  get getTaskTitle() {
    return this.storage?.retrieve("taskTitle");
  }

  setLifetimeMembership(value: any) {
    this.storage?.store("lifetime_membership", value);
  }

  get getLifetimeMembership() {
    return this.storage?.retrieve("lifetime_membership");
  }

  setReviewUpdates(value: any) {
    this.storage?.store("reviewUpdates", value);
  }

  get getReviewUpdates() {
    return this.storage?.retrieve("reviewUpdates");
  }

  setReviewUpdateDetails(value: any) {
    this.storage?.store("reviewUpdateDetails", value);
  }

  get getReviewUpdateDetails() {
    return this.storage?.retrieve("reviewUpdateDetails");
  }

  setImpersonatingUsername(value: any) {
    this.storage?.store("impersonatingUsername", value);
  }

  get getImpersonatingUsername() {
    return this.storage?.retrieve("impersonatingUsername");
  }

  setImpersonateToken(value: any) {
    this.storage?.store("impersonateToken", value);
  }

  get getImpersonateToken() {
    return this.storage?.retrieve("impersonateToken");
  }

  setIsConfirmedReviewUpdate(value: any) {
    this.storage?.store("isConfirmedReviewUpdate", value);
  }

  get getIsConfirmedReviewUpdate() {
    return this.storage?.retrieve("isConfirmedReviewUpdate");
  }

  setUserFileDetails(value: any) {
    this.storage?.store("user_file_details", value);
  }

  get getUserFileDetails() {
    return this.storage?.retrieve("user_file_details");
  }

  setUserFileId(value: any) {
    this.storage?.store("user_file_id", value);
  }

  get getUserFileId() {
    return this.storage?.retrieve("user_file_id");
  }

  setActiveTab(value: any) {
    this.storage?.store("activeTab", value);
  }

  get getActiveTab() {
    return this.storage?.retrieve("activeTab");
  }

  /************************ Volunteer Features *************************************/

  setSaveMessage(value: any) {
    this.storage?.store("save_message", value);
  }

  get getSaveMessage() {
    return this.storage?.retrieve("save_message");
  }

  setRedirectPageLink(value: any) {
    this.storage?.store("redirectPageLink", value);
  }

  get getRedirectPageLink() {
    return this.storage?.retrieve("redirectPageLink");
  }

  setIsFromOnbording(value: any) {
    this.storage?.store("isFromOnbording", value);
  }

  get getIsFromOnbording() {
    return this.storage?.retrieve("isFromOnbording");
  }

  setOnboardingId(value: any) {
    this.storage?.store("onboarding_id", value);
  }

  get getOnboardingId() {
    return this.storage?.retrieve("onboarding_id");
  }

  setRole(value: any) {
    this.storage?.store("role", value);
  }

  get getRole() {
    return this.storage?.retrieve("role");
  }


  setIsDirectPageLink(value: any) {
    this.storage?.store("isDirectPageLink", value);
  }

  get getIsDirectPageLink() {
    return this.storage?.retrieve("isDirectPageLink");
  }

  setUsername(value: any) {
    this.storage?.store("username", value);
  }

  get getUsername() {
    return this.storage?.retrieve("username");
  }

  setDelteacc(value: any) {
    this.storage?.store("delteacc", value);
  }

  get getDelteacc() {
    return this.storage?.retrieve("delteacc");
  }

  setSwitchRoleId(value: any) {
    this.storage?.store("switchRoleId", value);
  }

  get getSwitchRoleId() {
    return this.storage?.retrieve("switchRoleId");
  }

  setImportFileName(value: any) {
    this.storage?.store("import_file_name", value);
  }

  get getImportFileName() {
    return this.storage?.retrieve("import_file_name");
  }

  setUserProfileDetails(value: any) {
    this.storage?.store("user-profile-details", value);
  }

  get getUserProfileDetails() {
    return this.storage?.retrieve("user-profile-details");
  }

  setRouteUrl(value: any) {
    this.storage?.store("route_url", value);
  }

  get getRouteUrl() {
    return this.storage?.retrieve("route_url");
  }

  setImpersonateUserDetails(value: any) {
    this.storage?.store("impersonateUserDetails", value);
  }

  get getImpersonateUserDetails() {
    return this.storage?.retrieve("impersonateUserDetails");
  }
}
