import { environment } from "src/environments/environment";

export class APIEndPoint {
  public static ENDPOINT_URL = environment.ENDPOINT_URL;
  public static REGISTER_CALL_BACK_URL: "https://todo-a4473.firebaseapp.com/login";
  public static FORGOT_PASSWORD_CALL_BACK_URL: "https://todo-a4473.firebaseapp.com/reset-password";
}

/****************** Authentication API's **********************/
export const SIGN_IN = environment.ENDPOINT_URL + "/api/v1/sessions";
export const SIGN_UP = environment.ENDPOINT_URL + "/api/v1/registrations";
export const FORGOT_PASSWORD =
  environment.ENDPOINT_URL + "/api/v1/passwords/forgot";
export const RESET_PASSWORD = environment.ENDPOINT_URL + "/api/v1/passwords";

/****************** Admin Dashboard API's **********************/
export const FILTER_USER =
  environment.ENDPOINT_URL + "/api/v2/admin_dashboard/clinicians?pageNo=";
export const COMPLIANT_USERS =
  environment.ENDPOINT_URL + "/api/v2/admin_dashboard/compliant_users";
export const NON_COMPLIANT_USERS =
  environment.ENDPOINT_URL + "/api/v2/admin_dashboard/non_compliant_users";
export const CLINICIAN_DETAILS =
  environment.ENDPOINT_URL + "/api/v2/admin_dashboard/clinician_details/";
export const FILTER_DATA =
  environment.ENDPOINT_URL + "/api/v2/admin_dashboard/get_filters_data";
export const FILTER_COMPLIANT_USER =
  environment.ENDPOINT_URL + "/api/v2/admin_dashboard/compliant_users?";
export const FILTER_NON_COMPLIANT_USER =
  environment.ENDPOINT_URL + "/api/v2/admin_dashboard/non_compliant_users?";
export const DASHBOARD_SUMMARY =
  environment.ENDPOINT_URL + "/api/v2/admin_dashboard/summary";
export const API_END_POINT_DUE_TASKS =
  environment.ENDPOINT_URL + "/api/v2/admin_dashboard/clamant_tasks/";

export const STATE_LICENSE_REPORT =
  environment.ENDPOINT_URL + "/api/v2/admin_dashboard/state_license_report";

/****************** User Dashboard API's **********************/
export const SWITCH_ROLE = environment.ENDPOINT_URL + "/api/v1/sessions/switch";
export const BANNERS = environment.ENDPOINT_URL + "/api/v1/banners";
export const CLAMANT_TASKS =
  environment.ENDPOINT_URL + "/api/v2/clamant_tasks/";
export const CLAMANT_TASK_V2 =
  environment.ENDPOINT_URL + "/api/v2/clamant_tasks";
export const CLAMANT_TASK_V3 = environment.ENDPOINT_URL + "/api/v3/dashboard";
export const DASHBOARD_TASKS = environment.ENDPOINT_URL + "/api/v1/tasks";
export const USER_PROFILE = environment.ENDPOINT_URL + "/api/v1/profiles";
export const CONFIRM_EMAIL =
  environment.ENDPOINT_URL + "/api/v1/registrations/confirm_email";
export const FILE_DOWNLOAD =
  environment.ENDPOINT_URL + "/api/v1/secure_file_download/";

export const CE_TASK_REPORT_FILE_DOWNLOAD = environment.ENDPOINT_URL + 
  "/api/v1/tasks/"

/****************** Initial Licensing API's **********************/
export const LICENSING_REQUEST =
  environment.ENDPOINT_URL + "/api/v1/license_requests";

  /****************** Onboarding API's **********************/

  export const GET_ASSOCIATION_LIST = APIEndPoint.ENDPOINT_URL + '/api/v1/partner_organizations';
  export const ONBOARDING_BASE = APIEndPoint.ENDPOINT_URL + '/api/v1/onboarding';
  export const GET_ONBOARDING_SESSION = APIEndPoint.ENDPOINT_URL + '/api/v1/sessions/onboarding';
  export const VALIDATE_NPI_DETAILS = APIEndPoint.ENDPOINT_URL + '/api/v1/npi_numbers/parse?npi_number=';
  export const SEARCH_NPI_DETAILS = APIEndPoint.ENDPOINT_URL + '/api/v1/npi_numbers/search?first_name=';
  export const SEARCH_STATE_DETAILS = APIEndPoint.ENDPOINT_URL + '/api/v1/state_license_suggestions/search?';
  export const PAYMENT_PITCH = APIEndPoint.ENDPOINT_URL + '/api/v1/payments/pitch';

   /****************** users module API's **********************/
   export const GET_USERS = APIEndPoint.ENDPOINT_URL + '/api/v1/users';
   export const DELETE_USERS = APIEndPoint.ENDPOINT_URL + '/api/v1/organizations/';
   export const SEARCH_USERS = APIEndPoint.ENDPOINT_URL+'/api/v1/users?keywords[]=';
   export const SORT_USERS = APIEndPoint.ENDPOINT_URL+'/api/v1/users?sort_by=';
   export const IMPORT_DETAILS = APIEndPoint.ENDPOINT_URL + '/api/v1/imports/';
   export const GET_INVITE = APIEndPoint.ENDPOINT_URL + '/api/v1/invites' + '?page=';
   export const INVITE = APIEndPoint.ENDPOINT_URL + '/api/v1/invites';
   export const SEARCH_INVITE = APIEndPoint.ENDPOINT_URL + '/api/v1/invites?keywords[]=';
   export const SORT_INVITE = APIEndPoint.ENDPOINT_URL + '/api/v1/invites?sort_by=';
