import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[disableArrowUpDownWheel]',
  standalone:false
})
export class DisableArrowUpDownWheelDirective {
  constructor(private el: ElementRef) {
    let input: HTMLInputElement = el?.nativeElement;
    input.onwheel = () => {
      return false;
    };
    input.onkeydown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') return false;
      else return true;
    };
  }

}
