import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'customShortDate',
  standalone:false,
})
export class CustomShortDatePipe extends DatePipe implements PipeTransform {

  override transform(value: any, args?: any): any {
    if (value == 'Invalid Date' || value == 'undefined') {
      return "Invalid Date";
    } else {
      return super.transform(value, 'MM/dd/yyyy');
    }
  }

}
