import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appSanitizeInput]',
  standalone:false
})
export class SanitizeInputDirective {

  constructor(private el: ElementRef,
    private control: NgControl,
    private renderer: Renderer2) { }

    private sanitizeInput(value: string): string {
      return value.replace(/[^\w\.\-]/g, '').trim();
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent): void {
      event.preventDefault();
      const pastedData = event.clipboardData?.getData('text') || '';
      const sanitizedValue = this.sanitizeInput(pastedData);
      const inputElement = this.el.nativeElement as HTMLInputElement;
      const start = inputElement.selectionStart || 0;
      const end = inputElement.selectionEnd || 0;
      const finalValue = sanitizedValue;
      this.renderer.setProperty(inputElement, 'value', finalValue);
      if (this.control && this.control.control) {
        this.control.control.setValue(finalValue, { emitEvent: false });
      }
      inputElement.setSelectionRange(start + sanitizedValue.length, start + sanitizedValue.length);
    }

}
