import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'searchFilter',
  standalone:false,
})
export class SearchFilterPipe implements PipeTransform {
  transform(value: any, searchString: string): any[] {
    return value?.filter((str:any) => {
      return str?.name?.toLowerCase()?.includes(searchString?.toLowerCase());
    });
  }
}

@Pipe({
  name: 'searchFileCabinetFilter',
  standalone:false
})
export class SearchFileCabinetFilter implements PipeTransform {
  transform(value: any, searchString: string): any[] {
    return value?.filter((str:any) => {
      if(str !== null){
        return str?.label?.toLowerCase()?.includes(searchString?.toLowerCase());
      }
    });
  }
}
