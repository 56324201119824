import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[InputFormatter]',
  standalone:false
})
export class InputFormatterDirective {

  constructor(private el: ElementRef, private ngControl: NgControl) { }
  @Input() InputFormatter!: string;

  @HostListener("keydown", ["$event"])
  formatInputText(event: KeyboardEvent) {
    let e = <KeyboardEvent>event;
    if (this.checkCommonInputs(e)) {
      return;
    }
    switch (this.InputFormatter) {
      case "decimal":
        this.handleDecimalInput(e);
        break;
      case "numeric":
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
          e.preventDefault();
        }
        break;
      default:
        e.preventDefault();
        break;
    }
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const copiedText = event.clipboardData?.getData('text/plain');
    if (copiedText) {
      this.handlecopiedText(copiedText);
    }
  }

  handlecopiedText(copiedText: string) {
    const isDecimal = /^[0-9]*\.?[0-9]*$/.test(copiedText);
    if (!isDecimal) {
      return;
    }
    const decimalIndex = copiedText.indexOf('.');
    let decimalPart = decimalIndex > -1 ? copiedText.substring(decimalIndex + 1) : '';
    decimalPart = decimalPart.length > 3 ? decimalPart.slice(0, 3) : decimalPart;
    copiedText = copiedText.substring(0, decimalIndex + 1) + decimalPart
    this.el.nativeElement.value = copiedText ;
    this.ngControl?.control?.setValue(copiedText);
  }


  checkCommonInputs(e: KeyboardEvent) {
    const commonKeyCodes = [46, 8, 9, 27, 13];
    const ctrlOrMetaPressed = e.ctrlKey || e.metaKey;

    if (
      commonKeyCodes.includes(e.keyCode) ||
      (e.key === 'A' && ctrlOrMetaPressed) ||
      (e.key === 'C' && ctrlOrMetaPressed) ||
      (e.key === 'V' && ctrlOrMetaPressed) ||
      (e.key === 'X' && ctrlOrMetaPressed) ||
      (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Home' || e.key === 'End')
    ) {
      return true;
    } else {
      return false;
    }
  }

  handleDecimalInput(e: KeyboardEvent) {
    const isDigitOrDot = /[0-9.]/.test(e.key);
    const currentInput: string = this.el.nativeElement.value;
    const decimalIndex: number = currentInput.indexOf('.');
    let inputValueWithoutDecimal: string[] = [];

    if (!isDigitOrDot && !this.checkCommonInputs(e)) {
      e.preventDefault();
    }

    if (decimalIndex > -1) {
      inputValueWithoutDecimal = currentInput.split('.');
    }

    if (e.key === '.' && currentInput === '.') {
      this.el.nativeElement.value = '0.';
      return;
    }

    if (e.key === '.' && inputValueWithoutDecimal.length > 1) {
      e.preventDefault();
      return;
    }

    if (inputValueWithoutDecimal.length && inputValueWithoutDecimal[1]?.length > 2) {
      e.preventDefault();
      return;
    }
    
    if (e.key === 'Backspace') {
      return;
    }
    this.el.nativeElement.value = currentInput;
  }
}
