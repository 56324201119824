import { inject, Injectable, signal } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { ImpersonateUser } from "../core/interfaces/user.model";
import { StorageService } from "../core/store/storage.service";

@Injectable({
  providedIn: "root",
})
export class DataPassingService {
  private messageSource$ = new BehaviorSubject("");
  private taskSupported$ = new BehaviorSubject(true);
  private isFlagSet$ = new BehaviorSubject(false);
  private taskTitle$ = new BehaviorSubject("");
  private selectedCertIdList$ = new BehaviorSubject(null);
  impersonateUser: ImpersonateUser = {
    userName: null,
    userId: null,
    userType: null,
  };
  impersonateUserDetails = signal(this.impersonateUser);

  currentMessage = this.messageSource$.asObservable();
  private sessionMessage$ = new BehaviorSubject("");
  currentSessionMessage = this.sessionMessage$.asObservable();
  fileCabinetClearRecords$ = new BehaviorSubject(false);
  fileCabinetShowCategoryOnViewChange$ = new BehaviorSubject(null);
  updateOrganizationData$ = new Subject();
  isHomeStateSelected$ = new Subject();

  storageService = inject(StorageService);
  constructor() {}

  get getImpersonateUserDetails() {
    if (this.impersonateUserDetails().userId) {
      return this.impersonateUserDetails();
    }
    return this.storageService.getImpersonateUserDetails;
  }

  setImpersonateUserDetails(user: any) {
    this.impersonateUserDetails.set(user);
    this.storageService.setImpersonateUserDetails(user);
  }

  changeMessage(message: string) {
    this.messageSource$.next(message);
  }
  changeSessionMessage(message: string) {
    this.sessionMessage$.next(message);
  }

  setTaskSupported(status: any) {
    this.taskSupported$ = status;
  }

  getTaskSupported() {
    return this.taskSupported$;
  }

  setTaskTitle(title: any) {
    this.taskTitle$ = title;
  }

  getTaskTitle() {
    return this.taskTitle$;
  }

  setFlag(shareData: any) {
    this.isFlagSet$.next(shareData);
  }
  getFlag() {
    return this.isFlagSet$;
  }

  setSelectedCertIdList(data: any) {
    this.selectedCertIdList$ = data;
  }
  getSelectedCertIdList() {
    return this.selectedCertIdList$;
  }

  setOrganizationData(value: boolean) {
    this.updateOrganizationData$.next(value);
  }
  getOrganizationData() {
    return this.updateOrganizationData$;
  }

  setHomeState(value: boolean) {
    this.isHomeStateSelected$.next(value);
  }
  getHomeState() {
    return this.isHomeStateSelected$;
  }
}
