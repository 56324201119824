import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { CustomMediumDatePipe } from "./pipes/custom-medium-date.pipe";
import { CustomShortDatePipe } from "./pipes/custom-short-date.pipe";
import { KeyvaluePipe } from "./pipes/keyvalue.pipe";
import { SearchFileCabinetFilter, SearchFilterPipe } from "./pipes/sort.pipe";
import { InputFormatterDirective } from "./directives/input-formatter.directive";
import { SanitizeInputDirective } from "./directives/sanitize-input.directive";
import { DisableArrowUpDownWheelDirective } from "./directives/disable-arrow-up-down-wheel.directive";
import { AlertMessageComponent } from "./components/alert-message/alert-message.component";

@NgModule({
  declarations: [
    AlertMessageComponent,
    CustomMediumDatePipe,
    CustomShortDatePipe,
    KeyvaluePipe,
    SearchFilterPipe,
    SearchFileCabinetFilter,
    InputFormatterDirective,
    SanitizeInputDirective,
    DisableArrowUpDownWheelDirective,
  ],
  imports: [CommonModule],
  exports: [
    CustomMediumDatePipe,
    CustomShortDatePipe,
    KeyvaluePipe,
    SearchFilterPipe,
    SearchFileCabinetFilter,
    InputFormatterDirective,
    SanitizeInputDirective,
    DisableArrowUpDownWheelDirective,
    AlertMessageComponent,
  ],
  providers: [DatePipe, SearchFilterPipe],
})
export class CoreModule {}
