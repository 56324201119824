export enum AppConstant {
  //roles
  ADMIN_ROLE = "AdminRole",
  USER_ROLE = "userRole",
  MEMBER_ROLE = "MemberRole",
  PHYSICIAN_ROLE = "PhysicianRole",
  PHYSICIAN_ASSISTANT_ROLE = "PhysicianAssistantRole",
  LEADER_ROLE = "LeaderRole",

  ASSISTANT = "Assistant",
  SUPER_ADMIN = "superadmin",
  USER = "user",
  PHYSICIAN = "Physician",
  REPRESENTATIVE = "representative",

  // tasks
  PAST_DUE = "past-due",

  DANGER = "danger",
  WARNING = "warning",
  SUCCESS = "success",

  // usertypes
  DOCTOR_OF_OSTEOPATHY = "DoctorOfOsteopathy",
  NURSE_PRACTITIONER = "NursePractitioner",
  REGISTERED_NURSE = "RegisteredNurse",
  PHYSICIAN_ASSISTANT = "PhysicianAssistant",
  PHYSICAL_THERAPIST = "PhysicalTherapist",
  BEHAVIORAL_HEALTH = "BehavioralHealth",
  SOCIAL_WORKER = "SocialWorker",
  BASIC_USER = "BasicUser",
  ADMIN_USER = "Administrator",

  //task types
  STATE_LICENSES = "state-licenses",
  BOARD_CERTIFICATIONS = "board-certifications",
  CSA_REGISTRATIONS = "csa-registrations",
  DEA_REGISTRATIONS = "dea-registrations",
  OTHER_TRACKERS = "other-trackers",

  SORT_BY_ASC = "ASC",
  SORT_BY_DESC = "DESC",

  ON_TRACK = "On Track",
  OFF_TRACK = "Off Track",
  AT_RISK = "At Risk",

  TOKEN = "token",
  RTOKEN = "rtoken",
  DEBUG_MODE = "debugMode",
  IS_SUPER_ADMIN = "isSuperAdmin",

  /** CME support enums */
  PRACTICING_SPECIALTY = "Practicing Specialty",
  OTHER_METHODS = "Other Methods of Obtaining Continuing Medical Education",
  SINGLE_CERTIFICATE_ARCHIVED_TAB = "Certification moved to archived tab.",
  MULTIPLE_CERTIFICATE_ARCHIVED_TAB = "Certifications moved to archived tab.",
  UNARCHIVE_MESSAGE = "Certification successfully unarchived!",
  UT_DATE_FOR_WAIVER = "ut_date_picker_for_waiver",
  NO_SUBCATEGORY = "No Subcategory",

  MODALITY_TRACKER = "Modality Tracker",

  // licensing statuses
  APPLICATION_IN_PROCESS = "Application In Process",
  LICENSE_REQUESTED = "License Requested",
  LICENSE_ISSUED = "License Issued",
  APPLICATION_SUBMITTED = "Application Submitted",
  ONBOARDING = "Onboarding",

  // impersonation path
  IMPERSONATION_SIGN_IN_PATH = "impersonate-user/sign-in",
  IMPERSONATION_SIGN_OUT_PATH = "impersonate-user/sign-out",

  // ********Admin Dashboard********
  FILTER_SPECIALTY = "specialty",
  FILTER_STATE = "state",
  FILTER_TITLE = "title",
  FILTER_SORT_BY = "sortBy",
  LCSW = "LCSW",

  RENEW = "Renew",
  TASK_TYPE_STATE_LICENSE = "StateLicense",
}
