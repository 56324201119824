import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIEndPoint } from '../constants/api-endpoints';

@Injectable({
  providedIn: 'root',
})
export class CommonApiService {
  constructor(private http: HttpClient) {}

  saveFileUrl(file: any) {
    return this.http.post(APIEndPoint.ENDPOINT_URL + '/api/v1/files', file);
  }

  deleteFile(id: any) {
    return this.http.delete(APIEndPoint.ENDPOINT_URL + '/api/v1/files/' + id);
  }

  getCertificateImageVideo(url: any) {
    return this.http.get(url, { responseType: 'blob' });
  }

  stateLicenseActivateReq(id: number, payload: any) {
    return this.http.post(
      APIEndPoint.ENDPOINT_URL + '/api/v1/state_licenses/' + id + '/request_activation',
      payload
    );
  }

  stateLicenseDeactivateReq(id: number, payload: any) {
    return this.http.put(
      APIEndPoint.ENDPOINT_URL + '/api/v1/state_licenses/' + id + '/deactivate_state_license',
      payload
    );
  }

  getProfileInfo() {
    return this.http.get(APIEndPoint.ENDPOINT_URL + '/api/v1/profiles');
  }

  getSubCategoryList(obj: any) {
    return this.http.post(
      APIEndPoint.ENDPOINT_URL + '/api/v1/subcategories/parse_subcategories',
      obj
    );
  }
}
